import { render, staticRenderFns } from "./StudentLogin.vue?vue&type=template&id=57f97933&scoped=true&"
import script from "./StudentLogin.vue?vue&type=script&lang=js&"
export * from "./StudentLogin.vue?vue&type=script&lang=js&"
import style0 from "./StudentLogin.vue?vue&type=style&index=0&id=57f97933&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f97933",
  null
  
)

export default component.exports