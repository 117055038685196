export default {
    namespaced: true,

    state: () => ({
        loadingLeaves: false,
        leaves: [],
        leaveSetting: {},
    }),

    getters: {
        loadingLeaves(state) {
            return state.loadingLeaves;
        },

        leaves(state) {
            return state.leaves.map((leave, ind) => {
                leave.sl = ind + 1;
                leave.from_date = leave.leave_dates[0].date;
                leave.to_date = leave.leave_dates[leave.leave_dates.length - 1].date;
                return leave;
            });
        },

        leaveSetting(state) {
            return state.leaveSetting;
        },
    },

    mutations: {
        loadingLeaves(state, isLoading) {
            state.loadingLeaves = isLoading;
        },

        addLeaves(state, leaves) {
            state.leaves = leaves;
        },

        addLeaveSetting(state, setting) {
            state.leaveSetting = setting;
        },
    },

    actions: {
        async getLeaves(context, payload) {
            context.commit('loadingLeaves', true);

            let leaves = await axios.post(`${this.state.host}/get-leaves`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.leaves;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('addLeaves', leaves);
            context.commit('loadingLeaves', false);

        },
        
        async saveLeave(context, payload) {
            let isSuccess = false;

            let url = 'add-leave';
            if(payload.leave.id != null) {
                url = 'update-leave'
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
                isSuccess = false;
            })

            return isSuccess;
        },

        async deleteLeave(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-leave/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
                isSuccess = false;
            })

            return isSuccess;
        },

        async getLeaveSetting(context) {
            let setting = await axios.get(`${this.state.host}/get-leave-setting`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.leaveSetting;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            if(setting != null) {
                context.commit('addLeaveSetting', setting);
            }
        },

        async saveLeaveSetting(context, leaveSetting) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/save-leave-setting`, leaveSetting, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
                isSuccess = false;
            })

            return isSuccess;
        },

        async getLeaveSummary(context, payload) {
            let summary = await axios.post(`${this.state.host}/get-leave-summary`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.leaveSummary;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return summary;
        },
    }
}
